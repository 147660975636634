import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import LongContent from "./LongContent";
import Section from "./Section";

function Terms(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Box mt={5}>
        <Container>
        <LongContent>
          <h1>Terms</h1>
          <p>
            Terms ...
          </p>
        </LongContent>
        </Container>
      </Box>
    </Section>
  );
}

export default Terms;
