import React from "react";
import Meta from "./../components/Meta";
import Privacy from "./../components/Privacy";

function PrivacyPage(props) {
  return (
    <>
      <Meta title="Privacy" description="Privacy" />
      <Privacy
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section="privacy"
        key="privacy"
      />
    </>
  );
}

export default PrivacyPage;
