import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Chart Your Cosmic Course with Our Stellar Plans!"
        subtitle="Whether you're a starry-eyed beginner or an astrological aficionado, we have a plan tailored just for you. All plans include a 14-day free trial."
      />
    </>
  );
}

export default PricingPage;
