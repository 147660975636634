import React from "react";
import Meta from "./../components/Meta";
import Terms from "./../components/Terms";

function TermsPage(props) {
  return (
    <>
      <Meta title="Terms" description="Terms" />
      <Terms
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section="terms"
        key="terms"
      />
    </>
  );
}

export default TermsPage;
