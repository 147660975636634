import React from "react";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import AspectRatio from "./AspectRatio";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    // Add border that contrasts lightly with background color.
    // We use boxShadow so that it's hidden around outer edge
    // due to container <Card> having overflow: hidden
    boxShadow: `1px 1px 0 0 ${emphasize(theme.palette.background.paper, 0.08)}`,
    textAlign: "center",
  },
  imageContainer: {
    margin: "0 auto",
    maxWidth: "200px",
    marginBottom: "30px",
    "& img": {
      width: "100%",
    },
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Natal Chart Calculator",
      body: "Just like every superhero has an origin story, your natal chart is your personal story, revealing the alignment of the planets at the moment of your birth. Do you know that your natal chart is unique to you? Even twins born just minutes apart have different charts!",
      image: "chart.svg",
    },
    {
      title: "Zodiac Sign Interpretations",
      body: "Think of your zodiac sign as your cosmic DNA, giving you a glimpse into your inherent strengths, weaknesses, and tendencies. People with the same zodiac sign can have different personalities because of the influence of other planets in their charts.",
      image: "zodiac.svg",
    },
    {
      title: "Moon Sign Explorer",
      body: "Your Moon sign is like your emotional thermostat, dictating how you react to life's ups and downs and what makes you secure. Do you know your Moon reveals your emotional comfort? Leo Moon might crave attention, while Cancer Moon seeks comfort in nostalgia.",
      image: "moon.svg",
    },
    {
      title: "Sun Sign Horoscope",
      body: "Your Sun sign horoscope is like a cosmic weather report, giving you a general idea of the energies and opportunities that lie ahead. Your Sun sign is just the tip of the astrological iceberg! There's so much more to explore in your natal chart.",
      image: "sun.png",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Card raised={false}>
          <Grid container={true}>
            {items.map((item, index) => (
              <Grid
                item={true}
                xs={12}
                md={6}
                className={classes.gridItem}
                key={index}
              >
                <Box p={6}>
                  <div className={classes.imageContainer}>
                    <AspectRatio ratio={4 / 4}>
                      <img src={item.image} alt={item.title} />
                    </AspectRatio>
                  </div>
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">{item.body}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
