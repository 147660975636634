import React from "react";
import LongContent from "./LongContent";

function Story(props) {
  return (
    <LongContent>
      <h1>Our Story</h1>
      <p>
        Once upon a cosmic time, in a galaxy not so far away, lived a group of
        starry-eyed friends who were captivated by the mystique of the heavens.
        As they gazed upon the twinkling constellations, they couldn't help but
        wonder, "What secrets do the stars hold for us?"
      </p>
      <p>
        With curiosity as their guiding star, they embarked on a quest to
        unravel the cosmic mysteries. They delved deep into ancient texts,
        seeking the wisdom of sages and astrologers of bygone eras. The more
        they learned, the more they realized how interconnected we all are with
        the celestial bodies.
      </p>
      <p>
        However, they noticed that while astrology had been around for
        millennia, it was often misunderstood or dismissed as mere superstition.
        They wanted to change that perception and bring the beauty of astrology
        to the world in a way that was accessible, insightful, and fun.
      </p>
      <p>
        They decided to blend the ancient wisdom of astrology with the marvels
        of modern technology, creating a digital platform where anyone could
        explore their unique cosmic blueprint. They named their creation
        "Istrologer," a playful twist on astrology that hinted at their mission
        to guide people through their personal cosmic stories.
      </p>
      <p>
        The friends worked tirelessly, coding and crafting a user-friendly
        experience where anyone could generate their natal chart, discover their
        zodiac signs, and uncover their astrological strengths and challenges.
        They added features like a Transit Tracker, Compatibility Matchmaker,
        and even a daily horoscope for a dose of cosmic inspiration.
      </p>
      <p>
        Soon, Istrologer was born, and word of the magical platform spread like
        stardust across the galaxy. People from all walks of life, from curious
        novices to seasoned astrologers, flocked to Istrologer to embark on
        their personal cosmic journeys.
      </p>
      <p>
        And so, the starry-eyed friends who once gazed upon the heavens with
        wonder became cosmic co-pilots, guiding countless others through the
        galaxy of their lives. They transformed their passion for astrology into
        a stellar platform where everyone could unlock their cosmic secrets and
        embrace their unique place in the universe.
      </p>
      <p>
        As the friends looked back at their journey, they couldn't help but
        smile, knowing that the stars had indeed guided their way.
      </p>
      <p>
        The end... or rather, just the beginning of many more cosmic adventures
        with Istrologer.
      </p>
    </LongContent>
  );
}

export default Story;
